import React, { useState } from "react"
import { Card, Table } from "react-bootstrap"
import { Link } from "react-router-dom"

const DailyCleaningOtherPartnerList = ({ cardTitle, data, assignPartner, activePartner, handleSearch }) => {
  const [searchText, setSearchText] = useState("")

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="">{cardTitle}</h4>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="w-25">
              <input
                className="form-control rounded"
                placeholder="Enter search text"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(searchText)
                  }
                }}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
            </div>
            <button
              className="btn btn-primary ms-2"
              onClick={() => {
                handleSearch(searchText)
              }}
            >
              Search
            </button>
          </div> */}
          {data && data?.length > 0 && (
            <Table
              responsive
              classNames="table table-borderless table-nowrap mb-0"
            >
              <thead classNames="table-light">
                <tr>
                  <th>#</th>
                  <th>Partner</th>
                  {/* <th>City</th>
                                    <th>Locations</th>
                                    <th>Apartments</th>
                                    <th>Services</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      style={
                        item?._id == activePartner?._id
                          ? {
                            backgroundColor: "#4BB54320",
                          }
                          : {}
                      }
                      onClick={() => { }}
                    >
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={{
                            pathname: "/partners/partner-profile",
                            search: `?id=${item?.partnerId}`,
                          }}
                          className="font-weight-bold"
                          target="_blank"
                        >
                          <p className="m-0">
                          {item?.name}
                          </p>
                        </Link>
                      </td>
                      {/* <td>{item?.city?.name}</td>
                                            <td>
                                                {item?.location?.map((i) => {
                                                    return <span>➣ {i.name}</span>
                                                })}
                                            </td>
                                            <td>
                                                {item?.location?.map((i) => {
                                                    return <span>➣ {i.name}</span>
                                                })}
                                            </td>
                                            <td>
                                                {item?.services?.map((i) => {
                                                    return <span>➣ {i.name}<br /></span>
                                                })}
                                            </td> */}
                      <td>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => assignPartner(item?.servicepackageId,item?.agencyId,item?.partnerId)}
                        >
                          Assign
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm ms-2"
                            onClick={() =>
                              props.updateStatus(item._id, "rejected")
                            }
                          >
                            Reject
                          </button> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default DailyCleaningOtherPartnerList
