import React, {useEffect, useState} from "react"
import {Col, Offcanvas, Row} from "react-bootstrap"
import DailyCleaningOtherPartnerList from "./DailyCleaningOtherPartnerList"
import DoorstepRequestAcceptedTable from "./DoorstepRequestAcceptedTable"
import callApi from "../../../utils/apiCaller"
import DoorstepOtherPartnersList from "./DoorstepOtherPartnersList"
import swal from "sweetalert"

const DailyCleaningPartnerAssignDetails = ({
  showAssignModal,
  setShowAssignModal,
  data,
  updateBooking,
}) => {
  const [searchedPartners, setSearchedPartners] = useState([])

  useEffect(() => {
    getPartnersList()
  }, [])

  const getPartnersList = async () => {
    const reqBody = {
      bookingId: data?._id,
    }
    const res = await callApi(
      "v2/dailycleaning/getDailyCleaningReq",
      "POST",
      reqBody
    )
    if (res?.success) {
      setSearchedPartners(res?.data)
    }
  }

  const getSearchedPartners = (searchText) => {
    callApi(`ondemand/getcleaners`, "POST", {
      pageNum: 1,
      pageSize: 20,
      search: searchText,
    }).then((res) => {
      if (res.data) {
        setSearchedPartners(res.data)
      }
    })
  }

  const updateStatus = (id, status) => {
    //change this reqbody
    callApi(`bookingreq/updatestatus`, "POST", {
      ondemandbookingreqId: id,
      status: status,
    }).then((res) => {
      console.log("res", res)
      if (res.success) {
        updateBooking(data?._id)
        swal(
          "Assigned Succusfully",
          "Partner has been assigned successfully",
          "success"
        )
      }
    })
  }

  const assignPartner = (packageId, agencyId, partnerId) => {
    swal({
      title: "Are you sure ?",
      text: "You want to assign a new partner",
      icon: "warning",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        const info = {
          bookingId: data?._id,
          servicepackageId: packageId,
          agencyId: agencyId,
          partnerId: partnerId,
        }
        callApi(`v2/dailycleaning/assignCleaner`, "POST", info).then((res) => {
          if (res.success) {
            swal("Success", "Partner assigned successfully", "success")
            setShowAssignModal(false)
            updateBooking(data?._id)
            // data?.updateBookingDetails(data?._id);
          } else {
            swal("Error", res.message, "error")
          }
        })
      }
    })
  }
  return (
    <div>
      <Offcanvas
        show={showAssignModal}
        setShowAssignModal={() => setShowAssignModal(false)}
        placement="bottom"
        style={style.ModalStyle}
      >
        <div style={style.crossBtn}>
          <i
            className="dripicons-cross text-white"
            style={{fontSize: 20}}
            onClick={() => setShowAssignModal(false)}
          ></i>
        </div>
        <Offcanvas.Body className="pt-0">
          <Row className="mt-4">
            <Col className="col-2"></Col>
            <Col className="col-8">
              <div>
                <DailyCleaningOtherPartnerList
                  cardTitle={"Partners List"}
                  data={searchedPartners}
                  assignPartner={assignPartner}
                  activePartner={data?.activePartner}
                  handleSearch={getSearchedPartners}
                />

                {/* {data?.availAblePartner?.completed && (
                  <>
                    <DoorstepRequestAcceptedTable
                      data={data?.availAblePartner?.completed || []}
                      name="Assigned Requests"
                      updateStatus={updateStatus}
                      bookingIsClosed={data?.bookingIsClosed}
                      activePartner={data?.activePartner}
                    />
                  </>
                )}
                {data?.availAblePartner?.pending && (
                  <>
                    <DoorstepRequestAcceptedTable
                      data={data?.availAblePartner?.pending || []}
                      name="Pending Requests"
                      updateStatus={updateStatus}
                      bookingIsClosed={data?.bookingIsClosed}
                      activePartner={data?.activePartner}
                    />
                  </>
                )}
                {data?.availAblePartner?.cancelled && (
                  <>
                    <DoorstepRequestAcceptedTable
                      data={data?.availAblePartner?.cancelled || []}
                      name="Rejected/Closed Requests"
                      updateStatus={updateStatus}
                      bookingIsClosed={data?.bookingIsClosed}
                      activePartner={data?.activePartner}
                    />
                  </>
                )} */}
              </div>
            </Col>
            <Col className="col-2"></Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default DailyCleaningPartnerAssignDetails

const style = {
  ModalStyle: {
    marginTop: 30,
    borderTopLeftRadius: 14,
    borderTopRightRadius: 14,
    width: "100%",
    height: "95%",
    backgroundColor: "#FAFBFE",
  },
  crossBtn: {
    backgroundColor: "#3b3c36",
    borderRadius: 300,
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: "50%",
    top: -22,
    cursor: "pointer",
  },
}
